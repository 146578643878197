
export default {
    emits: [
        'done',
        'update:visible'
    ],
    props: {
        // 弹窗是否打开
        visible: Boolean,
        // 修改回显的数据
        data: Object
    },
    data() {
        return {
            // 表单数据
            form: Object.assign({}, this.data),
            // 表单验证规则
            rules: {
                name: [{
                    required: true,
                    message: this.$t('必填'),
                    type: 'string',
                    trigger: 'blur'
                }]
            },
            // 提交状态
            loading: false,
            // 是否是修改
            isUpdate: false
        };
    },
    watch: {
        data() {
            if (this.data) {
                this.form = Object.assign({}, this.data);
                this.isUpdate = true;
            } else {
                this.form = {};
                this.isUpdate = false;
            }
            if (this.$refs.form) {
                this.$refs.form.clearValidate();
            }
        }
    },
    methods: {
        /* 更新visible */
        updateVisible(value) {
            this.$emit('update:visible', value);
        },
        /* 保存编辑 */
        save() {
            this.$refs.form.validate().then(() => {
                this.loading = true;
                this.$http.post('/admin/role/update',Object.assign(this.form, {
                    role_type: "admin"
                })).then(res => {
                    this.loading = false;
                    if (res.data.code === 0) {
                        this.$message.success(res.data.msg);
                        this.form = {};
                        this.updateVisible(false);
                        this.$emit('done');
                    } else {
                        this.$message.error(res.data.msg);
                    }
                }).catch(e => {
                    this.loading = false;
                    this.$message.error(e.message);
                });
            }).catch(() => {});
        },
        onClose() {
            this.updateVisible(false);
        }
    }
}
